import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AppHeader from '@/components/AppHeader.vue';
import PlatformButton from '@/components/base-components/PlatformButton.vue';
import PlatformTextField from '@/components/base-components/PlatformTextField.vue';
import PlatformSelect from '@/components/base-components/PlatformSelect.vue';
import PlatformStepperHeader from '@/components/base-components/PlatformStepperHeader.vue';

const registerComponents = (): void => {
  Vue.component('platform-button', PlatformButton);
  Vue.component('platform-text-field', PlatformTextField);
  Vue.component('platform-select', PlatformSelect);
  Vue.component('platform-stepper-header', PlatformStepperHeader);
  Vue.component('app-header', AppHeader);
  Vue.component('font-awesome-icon', FontAwesomeIcon);
};

export default { registerComponents };

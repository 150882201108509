import { ActionTree, GetterTree } from 'vuex';
import State from '../types/State';
import TransactionService from '@/services/TransactionService';
import ErrorResponse from '@/models/ErrorResponse';
import { TransactionState } from '../types/TransactionState';
import TransactionResponse from '@/models/TransactionResponse';
import Transaction from '@/models/Transaction';
import router from '@/router';

const state = {
  transaction: undefined,
} as TransactionState;

const actions: ActionTree<TransactionState, State> = {
  submitTransaction: ({ commit }, transaction: Transaction): void => {
    commit('ackLoading');
    TransactionService.submitNewTransaction(transaction)
      .then((response) => {
        commit('setTransactionResult', response);
        router.push('/finished');
      })
      .catch((error: ErrorResponse) => {
        commit('updateError', error);
      })
      .finally(() => commit('releaseLoading'));
  },
};

const mutations = {
  setTransactionResult: (current: TransactionState, response: TransactionResponse): void => {
    current.transaction = response;
  },
};

const getters: GetterTree<TransactionState, State> = {
  transactionResult: (current) => current.transaction,
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};

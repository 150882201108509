






































import Vue from 'vue';
import { mapGetters } from 'vuex';
import TenantConfig from './models/TenantConfig';
import GoogleTagManagerService from '@/services/GoogleTagManagerService';
import State from './store/types/State';

export default Vue.extend({
  name: 'App',
  computed: mapGetters(['appIsLoading', 'appError', 'tenantConfig']),
  created() {
    this.$store.dispatch('getTenantConfig');
    this.unsubscribe = this.$store.subscribe((mutation, state: State) => {
      if (mutation.type === 'setTenantTheme') {
        this.$vuetify.theme.themes.light.primary = state.tenant.themeColors.primaryColor;
        this.$vuetify.theme.themes.light.secondary = state.tenant.themeColors.secondaryColor;
        this.$vuetify.theme.themes.light.accent = state.tenant.themeColors.accentColor;
      }
    }).bind(this);
    this.$store.dispatch('getCurrentStudent');
    this.$store.dispatch('loadTenantTheme');
  },
  destroyed() {
    this.unsubscribe();
  },
  watch: {
    tenantConfig: {
      async handler(config: TenantConfig | undefined) {
        if (config) {
          document.title = this.$t('meta.title', { tenantName: config.tenantName }).toString();

          if (config.favIconPath) {
            const favIconLink = document.createElement('link');
            favIconLink.rel = 'icon';
            favIconLink.href = config.favIconPath;
            document.getElementsByTagName('head')[0].append(favIconLink);
          }

          if (config.googleTagManagerId) {
            GoogleTagManagerService.run(config.googleTagManagerId, window, document);
          }
        }
      },
    },
  },
});

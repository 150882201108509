import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { VueMaskDirective } from 'v-mask';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import 'vuetify/dist/vuetify.min.css';
import i18n from './i18n';

library.add(fas);
library.add(far);
Vue.use(Vuetify);
Vue.directive('mask', VueMaskDirective);

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string,
  },
});













import { VSelect } from 'vuetify/lib';

export default VSelect.extend({
  name: 'PlatformSelect',
  inheritAttrs: true,
  extends: VSelect,
  props: {
    className: String,
  },
});

import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import CouponService from '@/services/CouponService';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/finished',
    alias: '/sucesso',
    name: 'CheckoutFinished',
    component: () => import('../views/CheckoutFinished.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '/coupon/:code',
    alias: '/cupom/:code',
    name: 'coupon',
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      try {
        const coupon = await CouponService.getByCode(to.params.code);
        next({ name: 'checkout', query: { plan: coupon.planId, coupon: coupon.code } });
      } catch (error) {
        next({ name: '404' });
      }
    },
  },
  {
    path: '/inscricao/:planId',
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      next({ name: 'checkout', query: { plan: to.params.planId } });
    },
  },
  {
    path: '/renew',
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      next({ name: 'checkout', query: { plan: to.params.planId } });
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

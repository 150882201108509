import { ActionTree, GetterTree } from 'vuex';
import Student from '@/models/Student';
import StudentService from '@/services/StudentService';
import State from '../types/State';
import { StudentState } from '../types/StudentState';

const state = {
  current: undefined,
} as StudentState;

const actions: ActionTree<StudentState, State> = {
  getCurrentStudent: ({ commit }): void => {
    commit('ackLoading');
    StudentService.getCurrentStudent()
      .then((response: Student | undefined) => {
        commit('setCurrentUser', response);
      })
      .catch(() => commit('setCurrentUser', undefined))
      .finally(() => commit('releaseLoading'));
  },
};

const mutations = {
  setCurrentUser: (current: StudentState, student: Student | undefined): void => {
    current.current = student;
  },
};

const getters: GetterTree<StudentState, State> = {
  currentStudent: (current) => current.current,
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};

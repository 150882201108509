import ErrorResponse from '@/models/ErrorResponse';
import i18n from '@/plugins/i18n';

export default class CommonFormatter {
  static removeAnyNonNumber(value: string): string {
    return value.replaceAll(/[^0-9]/g, '');
  }

  static formatErrorMessage(error: ErrorResponse): string {
    return i18n.t(`errors.${error.errorCode}`, error.params).toString();
  }
}

import { ActionTree, GetterTree } from 'vuex';
import State from '../types/State';
import { CheckoutState } from '../types/CheckoutState';
import { AppState } from '../types/AppState';
import ErrorResponse from '@/models/ErrorResponse';
import CommonFormatter from '@/formatters/CommonFormatter';

const initialState = {
  loadingRequests: 0,
  error: {
    display: false,
  },
} as AppState;

const actions: ActionTree<CheckoutState, State> = {
  startLoading: ({ commit }): void => {
    commit('ackLoading');
  },
  finishLoading: ({ commit }): void => {
    commit('releaseLoading');
  },
  setError: ({ commit }, params: { error?: ErrorResponse }): void => {
    commit('updateError', params.error);
  },
};

const mutations = {
  ackLoading: (current: AppState): void => {
    current.loadingRequests += 1;
  },
  releaseLoading: (current: AppState): void => {
    if (current.loadingRequests > 0) {
      current.loadingRequests -= 1;
    }
  },
  updateError: (current: AppState, error?: ErrorResponse): void => {
    if (error) {
      current.error = {
        message: CommonFormatter.formatErrorMessage(error),
        display: true,
      };
    } else {
      current.error = {
        message: undefined,
        display: false,
      };
    }
  },
};

const getters: GetterTree<AppState, State> = {
  appIsLoading: (current) => current.loadingRequests > 0,
  appError: (current) => current.error,
};

export default {
  namespace: true,
  state: initialState,
  actions,
  mutations,
  getters,
};

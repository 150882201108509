class UserMetadata {
  browser?: string;

  browserVersion?: string;

  platform?: string;

  platformVersion?: string;
}

export default UserMetadata;

import { plainToClass } from 'class-transformer';
import PaymentAPIRequestService from './api/PaymentAPIRequestService';
import Student from '@/models/Student';

class StudentService extends PaymentAPIRequestService {
  async getCurrentStudent(): Promise<Student | undefined> {
    const url = '/students/current';
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      if (body) {
        return Promise.resolve(plainToClass(Student, body));
      }
      return Promise.resolve(undefined);
    }

    return Promise.reject(response.body);
  }
}

export default new StudentService();

export default class Coupon {
  id: number;

  planId: string;

  title: string;

  code: string;

  fee: number;

  percentage: number;

  installments: number;

  shareUrl: string;

  enrollmentsReleased: number;

  startedAt: Date;

  endedAt: Date;

  published: boolean;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;
}

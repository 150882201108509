import TenantURLs from './TenantURLs';

export default class TenantConfig {
  tenantName: string;

  mainLogoPath: string;

  notificationBoardPicture: string;

  paymentLogoUrlPath: string;

  urls: TenantURLs;

  discountPix: number;

  discountBoleto: number;

  discountCCOneTime: number;

  favIconPath: string;

  googleTagManagerId: string;
}

import { ActionTree, GetterTree } from 'vuex';
import TenantConfig from '@/models/TenantConfig';
import TenantService from '@/services/TenantService';
import State from '../types/State';
import { TenantState } from '../types/TenantState';

const state = {
  config: undefined,
} as TenantState;

const actions: ActionTree<TenantState, State> = {
  getTenantConfig: ({ commit }): void => {
    commit('ackLoading');
    TenantService.loadTenantConfig()
      .then((response: TenantConfig) => {
        commit('setConfig', response);
      })
      .finally(() => commit('releaseLoading'));
  },
  loadTenantTheme: ({ commit }): void => {
    commit('ackLoading');
    TenantService.loadTenantTheme()
      .then((tenantColors) => {
        commit('setTenantTheme', tenantColors);
      })
      .finally(() => commit('releaseLoading'));
  },
};

const mutations = {
  setConfig: (current: TenantState, newConfig: TenantConfig): void => {
    current.config = newConfig;
  },
  setTenantTheme: (current: TenantState, tenantColors: Record<string, string>): void => {
    current.themeColors = tenantColors;
  },
};

const getters: GetterTree<TenantState, State> = {
  appLogo: (current) => current?.config?.paymentLogoUrlPath,
  tenantUrls: (current) => current.config.urls,
  tenantColors: (current) => current.themeColors,
  tenantConfig: (current) => current.config,
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};

import { plainToClass } from 'class-transformer';
import PaymentAPIRequestService from './api/PaymentAPIRequestService';
import Plan from '@/models/Plan';

class PlanService extends PaymentAPIRequestService {
  async getPlanById(planId: string): Promise<Plan> {
    const url = `/plans/${planId}`;
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      return Promise.resolve(plainToClass(Plan, body));
    }

    return Promise.reject(response.body);
  }

  async getRenewable(): Promise<Plan[]> {
    const url = '/plans?renewable=true';
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      return Promise.resolve(body.map((plan) => plainToClass(Plan, plan)));
    }

    return Promise.reject(response.body);
  }
}

export default new PlanService();

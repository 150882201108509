import { plainToClass } from 'class-transformer';
import TenantConfig from '@/models/TenantConfig';
import PaymentAPIRequestService from './api/PaymentAPIRequestService';

class TenantService extends PaymentAPIRequestService {
  async loadTenantConfig(): Promise<TenantConfig> {
    const url = '/tenants/config';
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      return Promise.resolve(plainToClass(TenantConfig, body));
    }

    return Promise.reject(response.body);
  }

  async loadTenantTheme(): Promise<Record<string, string>> {
    const url = '/tenants/theme';
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      return Promise.resolve(body);
    }

    return Promise.reject(response.body);
  }
}

export default new TenantService();

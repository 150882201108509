import { ActionTree, GetterTree } from 'vuex';
import State from '../types/State';
import { CheckoutState } from '../types/CheckoutState';
import PlanService from '@/services/PlanService';
import Plan from '@/models/Plan';
import CouponService from '@/services/CouponService';
import Coupon from '@/models/Coupon';

const state = {
  plan: undefined,
  coupon: {
    data: undefined,
    couponLoading: false,
    couponError: false,
  },
} as CheckoutState;

const actions: ActionTree<CheckoutState, State> = {
  getPlanInfo: ({ commit }, params): void => {
    commit('ackLoading');
    PlanService.getPlanById(params.planId)
      .then((response: Plan) => {
        response.id = params.planId;
        commit('setCurrentPlan', response);
      })
      .finally(() => commit('releaseLoading'));
  },
  validateCoupon: ({ commit }, params): void => {
    commit('setCouponLoading', true);
    CouponService.validate(params.coupon, params.planId)
      .then((response: Coupon) => {
        response.id = params.planId;
        commit('setCurrentCoupon', response);
      }).catch(() => {
        commit('setInvalidCoupon');
      }).finally(() => {
        commit('setCouponLoading', false);
      });
  },
};

const mutations = {
  setCurrentPlan: (current: CheckoutState, newPlan: Plan): void => {
    current.plan = newPlan;
  },
  setCurrentCoupon: (current: CheckoutState, newCoupon: Coupon): void => {
    if (newCoupon) {
      current.coupon.data = newCoupon;
      current.coupon.couponError = false;
    } else {
      mutations.setInvalidCoupon(current);
    }
  },
  setInvalidCoupon: (current: CheckoutState): void => {
    current.coupon.data = undefined;
    current.coupon.couponError = true;
  },
  setCouponLoading: (current: CheckoutState, isLoading: boolean): void => {
    current.coupon.couponLoading = isLoading;
  },
};

const getters: GetterTree<CheckoutState, State> = {
  checkoutPlan: (current) => current.plan,
  checkoutCoupon: (current) => current.coupon,
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};

class ErrorResponse {
  status: number;

  message: string;

  errorCode: string;

  params: Record<string, string | number>
}

export default ErrorResponse;

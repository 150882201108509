import Vue from 'vue';
import Vuex from 'vuex';
import State from './types/State';
import TenantVuex from './ modules/TenantVuex';
import CheckoutVuex from './ modules/CheckoutVuex';
import AppVuex from './ modules/AppVuex';
import TransactionVuex from './ modules/TransactionVuex';
import StudentVuex from './ modules/StudentVuex';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  modules: {
    tenant: TenantVuex,
    checkout: CheckoutVuex,
    app: AppVuex,
    transaction: TransactionVuex,
    student: StudentVuex,
  },
});

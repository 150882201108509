











import { VStepper } from 'vuetify/lib';

export default VStepper.extend({
  name: 'PlatformStepperHeader',
  inheritAttrs: true,
  extends: VStepper,
  props: {
    className: String,
  },
});

import { Exclude } from 'class-transformer';

export default class Student {
  id: number;

  firstName: string;

  lastName: string;

  dateOfBirth: Date;

  cpf: string;

  email: string;

  phone: string;

  cellphone: string;

  zipCode: string;

  neighborhood: string;

  address: string;

  number: string;

  complement: string;

  city: string;

  state: string;

  tags: string;

  avatar: string;

  monitor: boolean;

  activated: boolean;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  hasPastPlans: boolean;

  @Exclude()
  tenantId: string;
}

export default class Plan {
  id: string;

  title: string;

  subtitle: string;

  fee: number;

  accessDays: number;

  recurringPayment: boolean;

  paymentAtSight: boolean;

  installmentPayment: boolean;

  installments:number;

  installmentFee: number;

  paymentDeadline: Date;

  thirtyDayPlan: boolean;

  thirtyDayPlanRecurringPayment: string

  combo: boolean;

  renewed: boolean;

  position: number;

  published: boolean;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;
}

import Cookies from 'js-cookie';
import Cookie from '@/enums/Cookie';

export type HttpMethods = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS';

export default abstract class BaseApiRequestService {
  protected abstract readonly serviceURL: string;

  public getServiceUrl(): string {
    return this.serviceURL;
  }

  public async request(
    method: HttpMethods,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: Record<string, any> = {},
    headers: { [key: string]: string } = {},
  ) : Promise<Response> {
    const defaultHeaders: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };
    const token = Cookies.get(Cookie.STUDENT_JWT);
    if (token) {
      defaultHeaders.Authorization = `Bearer ${token}`;
    }

    const requestOptions = {
      method,
      headers: { ...defaultHeaders, ...headers },
      body: method === 'GET' ? null : JSON.stringify(body),
    };

    return fetch(`${this.getServiceUrl()}${url}`, requestOptions);
  }
}

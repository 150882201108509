import { plainToClass } from 'class-transformer';
import { UAParser } from 'ua-parser-js';
import PaymentAPIRequestService from './api/PaymentAPIRequestService';
import Plan from '@/models/Plan';
import Transaction from '@/models/Transaction';
import ErrorResponse from '@/models/ErrorResponse';
import UserMetadata from '@/models/UserMetadata';

class TransactionService extends PaymentAPIRequestService {
  private static getUserMetadata(): UserMetadata {
    const uaParser = new UAParser(window.navigator.userAgent);
    const metadata = new UserMetadata();
    metadata.browser = uaParser.getBrowser().name;
    metadata.browserVersion = uaParser.getBrowser().version;
    metadata.platform = uaParser.getOS().name;
    metadata.platformVersion = uaParser.getOS().version;

    return metadata;
  }

  async submitNewTransaction(transaction: Transaction): Promise<Plan> {
    transaction.userMetadata = TransactionService.getUserMetadata();

    const url = '/transactions/';
    const response = await this.request('POST', url, transaction);
    const body = await response.json();

    if (response.ok) {
      return Promise.resolve(body);
    }

    return Promise.reject(plainToClass(ErrorResponse, body));
  }
}

export default new TransactionService();

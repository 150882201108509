



























import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'AppHeader',
  computed: mapGetters(['appLogo']),
});












import { VTextField } from 'vuetify/lib';

export default VTextField.extend({
  name: 'PlatformTextField',
  inheritAttrs: true,
  extends: VTextField,
  props: {
    className: String,
  },
});

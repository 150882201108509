










import { VBtn } from 'vuetify/lib';

export default VBtn.extend({
  name: 'PlataformButton',
  inheritAttrs: true,
  extends: VBtn,
  props: {
    className: String,
  },
});

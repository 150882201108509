import { plainToClass } from 'class-transformer';
import PaymentAPIRequestService from './api/PaymentAPIRequestService';
import Coupon from '@/models/Coupon';

class CouponService extends PaymentAPIRequestService {
  async validate(coupon: string, planId: string): Promise<Coupon> {
    const url = `/coupons/validate?plan=${planId}&coupon=${coupon}`;
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      return Promise.resolve(plainToClass(Coupon, body));
    }

    return Promise.reject(response.body);
  }

  async getByCode(coupon: string): Promise<Coupon> {
    const url = `/coupons/${coupon}`;
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      return Promise.resolve(plainToClass(Coupon, body));
    }

    return Promise.reject(response.body);
  }
}

export default new CouponService();
